import { useEffect } from 'react';

import { signIn } from 'next-auth/react';

import { NextPage, useRouter } from '@dxp-next';

const LoginPage: NextPage = () => {
  const { isReady, query } = useRouter();

  useEffect(() => {
    if (!isReady) return;

    const callbackUrl = (query?.returnUrl as string) || '/my-zone/dashboard';
    signIn('okta', { callbackUrl: callbackUrl });
  }, [isReady, query]);

  return null;
};

export default LoginPage;
